.card.ecommerce-card img {
  max-width: 350px;
  max-height: 350px;
  padding: 20px;
}

.loading {
  text-align: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: auto;
  display: flex;
  position: relative;
}

.loading div {
  opacity: 1;
  border: 4px solid #fff;
  border-radius: 50%;
  width: 100%;
  margin-top: -10px;
  animation: 1s cubic-bezier(0, .2, .8, 1) infinite loading;
  position: absolute;
}

.loading div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes loading {
  0% {
    opacity: 1;
    width: 0;
    height: 0;
    top: 18px;
    left: 18px;
  }

  100% {
    opacity: 0;
    width: 36px;
    height: 36px;
    top: 0;
    left: 0;
  }
}

.loading.gray div {
  border: 4px solid #888;
}

.debug {
  position: fixed;
  bottom: 0;
}

.content.blank-page {
  overflow: overlay;
  margin-left: 0;
  transition: none;
  overflow-x: hidden;
  padding: 0 !important;
}

.bs-stepper-content {
  height: fit-content;
}

.alert {
  text-align: center;
  margin-top: 14px;
  padding: 16px;
}

.nav-item:not(:first-of-type) {
  display: none;
}

.header-navbar {
  height: 60px;
}

.app-content, .app-ecommerce-details, .ecommerce-application {
  padding-top: 80px !important;
}

.horizontal-menu-wrapper {
  display: none;
}

body {
  overflow-y: scroll;
}

.header-navbar {
  top: 0;
}
/*# sourceMappingURL=index.77a0fffa.css.map */
